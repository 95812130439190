

export class RealizationCollapseClass{
    container;
    constructor({ container } = {}){
        this.container = document.querySelectorAll(container);
        this.init();
    }
    init(){
        $(this.container).on('click', '[data-toggle]', (e) => {
            e.preventDefault();
            const $post = $(e.currentTarget).closest('.rel-list-item');
            const $teaser = $post.find('[data-teaser]');
            const $content = $post.find('[data-content]');
            const active = $post.is('.active');
            if(active){
                $post.removeClass('active');
                $teaser.slideDown();
                $content.slideUp();

                
                window.scrollTo({
                    top: $post.offset().top - 50,
                    behavior: 'smooth'
                });
                
                
            }else{
                $teaser.slideUp();
                $content.slideDown();
                
                $post.addClass('active');

                window.scrollTo({
                    top: $post.offset().top - 50,
                    behavior: 'smooth'
                });
            }
        });
    }

} 
import $ from 'jquery';

export class TabsClass{
    el;
    $tabsTabs;
    $tabsContent;
    constructor({el} = {}) {
        this.el = el;

        this.$tabsContent = $(this.el).find('.tab__content');
        this.$tabsTabs = $(this.el).find('.tabs__item');
        this.$tabsTabs.filter(':not(.active)').each((i,el)=>{
            const index = $(el).index();
            this.$tabsContent.eq(index).hide();

        });

        console.log('Tabs created',{el});

        $(this.el).on('click', '.tabs__item', e => {
            e.preventDefault();
            const index = $(e.currentTarget).index();
            console.log({index});
            this.$tabsContent.hide();
            this.$tabsContent.eq(index).show();
            
            this.$tabsTabs.removeClass('active');
            this.$tabsTabs.eq(index).addClass('active');
        });
        $(this.el).on('click', '[tab-next]', e => {
            e.preventDefault();
            var index = $(e.currentTarget).closest('.tabs').find('.tabs__item.active').index();
            if(index < this.$tabsTabs.length - 1){
                index += 1;
            }else{
                index = 0;
            }
            console.log({index});
            this.$tabsContent.hide();
            this.$tabsContent.eq(index).show();
            
            this.$tabsTabs.removeClass('active');
            this.$tabsTabs.eq(index).addClass('active');
        });
        $(this.el).on('click', '[tab-prev]', e => {
            e.preventDefault();
            var  index = $(e.currentTarget).closest('.tabs').find('.tabs__item.active').index();
            if(index > 0){
                index -= 1;
            }else{
                index = this.$tabsTabs.length - 1;
            }
            console.log({index});
            this.$tabsContent.hide();
            this.$tabsContent.eq(index).show();
            
            this.$tabsTabs.removeClass('active');
            this.$tabsTabs.eq(index).addClass('active');
        });
    }

}

const tabs = [];
export function init(){

    document.querySelectorAll('.tabs').forEach(el => {
        var t = new TabsClass({el: el});
        tabs.push(t);
    
    });
    window['tabs'] = tabs;
}
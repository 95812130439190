
export class RealizationsFilterClass{
    $container;
    bottomSwiper
    rSwiper
    constructor({ bottomSwiper, rSwiper}){
        this.bottomSwiper = bottomSwiper;
        this.rSwiper = rSwiper;

        this.$container = $('.filters__buttons');
        this.init();
    }

    init(){
        this.$container.on('click', 'li', this.onClick.bind(this));
    }
    onClick(e){
        var $target = $(e.currentTarget);
        var id = $target.data('id') || null;

        this.$container.find('li').removeClass('active');
        $target.addClass('active');

        this.filterRealizations();

    }

    getEnabled(){
        const buttons = Array.from(this.$container.get(0).querySelectorAll('li.active'));
        return buttons.map( el =>{
            return el.dataset.id;
        }).filter(id => !!id);
    }

    hasFilter(filter, ids = []){
      
        return ids.some( id => {
            return String(filter).indexOf(id) !== -1;
        });
    }

    filterRealizations(){
        var ids = this.getEnabled();
        if(ids.length === 0){
            $("[data-filter]").show();
        }else{

            $('[data-filter]').each((i, el) => {
                const $el = $(el);
                if(this.hasFilter(el.dataset.filter, ids)){
                    $el.show();
                }
                else{
                    $el.hide();
                }
            });
        }

        this.toggleMessage();

        this.rSwiper.update();
        this.bottomSwiper.update();
    }
    toggleMessage(){
        if($('[data-filter]:visible').length === 0){
            $('.filters__message').show();
        }else{
            $('.filters__message').hide();
        }
    }
}


class Collapse{
    $el;
    constructor({el}){
        this.$el = $(el);
        this.$el.on('click', e=>{
            const id = this.$el.attr('data-collapse-toggle');
            const min = this.$el.attr('data-collapse-min') || 0;
            // const id = this.$el.attr('data-collapse-min') || 0;
            const $container = $(id);
            const active = this.$el.is(".active");
            if(active){
                $container.stop().css({
                    height: $container.prop('scrollHeight') 
                }).animate({
                    height: min
                },333);
                $container.add(this.$el).removeClass('active');
            }else{
                $container.add(this.$el).addClass('active');
                $container.stop().animate({
                    height: $container.prop('scrollHeight')
                },333).queue(()=>{
                    $container.css('height', 'auto');
                });
            }

        });
    }

}


document.querySelectorAll("[data-collapse-toggle]").forEach(el=>{
    new Collapse({el});
});
// Webpack
// import 'sal.js/sal.css';

// import sal from 'sal.js'

// const scrollAnimations = sal();

// // scrollAnimations.disable();

// export {scrollAnimations};

import 'aos/dist/aos.css'; // You can also use <link> for styles
import AOS from 'aos';
// ..
export function startAOS(){

    AOS.init();
}



import { RealizationsFilterClass } from "./RealizationsFilters";






// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';
import { RealizationCollapseClass } from "./RealizationsCollapse";


export const rSwiper = new Swiper('#rSwiper', {
	
	// If we need pagination
	// pagination: {
	// 	el: '#rSwiper .swiper-pagination',
	// 	clickable: true
	// },

	// Navigation arrows
	initialSlide: 2,
	navigation: false,
	watchSlidesProgress: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	// Default parameters
	slidesPerView: 1,
	spaceBetween: 0,
	// thumbs: {
	// 	swiper: bottomSwiper
	// }
	// Responsive breakpoints
	// breakpoints: {
	// 	// when window width is >= 320px
	// 	320: {
	// 		slidesPerView: 1,
	// 		// spaceBetween: 0
	// 	},
	// 	// when window width is >= 480px
	// 	480: {
	// 		slidesPerView: 2,
	// 		// spaceBetween: 0
	// 	},
	// 	// when window width is >= 640px
	// 	1000: {
	// 		slidesPerView: 3,
	// 		// spaceBetween: 0
	// 	}
	// }
});




export const bottomSwiper = new Swiper('#bottomSwiper', {
	
	// If we need pagination
	// pagination: {
	// 	el: '#rSwiper .swiper-pagination',
	// 	clickable: true
	// },

	// Navigation arrows
	// loop: true,
	navigation: false,
	// freeMode: true,
	watchSlidesProgress: true,
	// Default parameters
	slidesPerView: 3,
	initialSlide: 2,
	spaceBetween: 0,
	centeredSlides: true,
	observer: true,
	simulateTouch: false,
	allowTouchMove: false,
	
	// thumbs: {
	// 	swiper: rSwiper
	// }
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 320px
		// 320: {
		// 	slidesPerView: 1,
		// 	// spaceBetween: 0
		// },
		// // when window width is >= 480px
		// 480: {
		// 	slidesPerView: 2,
		// 	// spaceBetween: 0
		// },
		// when window width is >= 640px
		1000: {
			slidesPerView: 5,
			spaceBetween: 20,
			// spaceBetween: 0
		},
		// 2000: {
		// 	slidesPerView: 7,
		// 	spaceBetween: 30,
		// 	// spaceBetween: 0
		// },
		2200: {
			slidesPerView: 7,
			spaceBetween: 20,
			// spaceBetween: 0
		}
	}
});



rSwiper.on('slideChange', swiper => {
	
	// $btnsRef.eq(swiper.realIndex).removeClass('hollow').siblings().addClass('hollow');
	bottomSwiper.slideTo(swiper.realIndex);

	updateRealisation(swiper.realIndex);
});

function updateRealisation(index){
	// var items = [
	// 	{date:'07.2021 – 12.2022', name:'Warsaw Data Hub (dawniej NCT)', desc:'Warsaw Data Hub to nowoczesne Centrum Przetwarzania Danych zlokalizowane pod Warszawą. Podczas realizacji WDH zastosowano najnowocześniejsze standardy i technologie pozwalające na uzyskanie bardzo wysokiego poziomu'},
	// 	{date:'04.2021 – 06.2022', name:'Lorem ipsum dolor sit amet', desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a tincidunt ipsum, id mollis mauris. Praesent id metus quis massa efficitur vehicula. Praesent tincidunt pulvinar odio, id suscipit tellus vestibulum ac. Sed tincidunt suscipit neque, non vulputate ex rutrum in. Duis nec lectus mollis, eleifend libero quis, elementum enim. In in pretium purus. Praesent eget feugiat magna. Sed vehicula sagittis fringilla. Ut efficitur auctor ligula sit amet convallis'},
	// 	{date:'01.2021 – 03.2022', name:'Warsaw Data Hub (dawniej NCT)', desc:'Warsaw Data Hub to nowoczesne Centrum Przetwarzania Danych zlokalizowane pod Warszawą. Podczas realizacji WDH zastosowano najnowocześniejsze standardy i technologie pozwalające na uzyskanie bardzo wysokiego poziomu'},
	// 	{date:'04.2021 – 06.2022', name:'Lorem ipsum dolor sit amet', desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a tincidunt ipsum, id mollis mauris. Praesent id metus quis massa efficitur vehicula. Praesent tincidunt pulvinar odio, id suscipit tellus vestibulum ac. Sed tincidunt suscipit neque, non vulputate ex rutrum in. Duis nec lectus mollis, eleifend libero quis, elementum enim. In in pretium purus. Praesent eget feugiat magna. Sed vehicula sagittis fringilla. Ut efficitur auctor ligula sit amet convallis'},
	// 	{date:'01.2021 – 03.2022', name:'Warsaw Data Hub (dawniej NCT)', desc:'Warsaw Data Hub to nowoczesne Centrum Przetwarzania Danych zlokalizowane pod Warszawą. Podczas realizacji WDH zastosowano najnowocześniejsze standardy i technologie pozwalające na uzyskanie bardzo wysokiego poziomu'},
	// ];
	var items = window['realizations'];
	$('[data-rel-name]').text(items[index].title);
	$('[data-rel-desc]').html(items[index].teaser);
	$('[data-rel-date]').text(items[index].time_span);
}


$("#loadMore").on('click', function(){
	$(this).hide();
	$('.realizations-list.additional').slideDown();
});




const filters = new RealizationsFilterClass({
    bottomSwiper: bottomSwiper,
    rSwiper: rSwiper
});

filters.init();





new RealizationCollapseClass({
	container: '.realizations-list',
});
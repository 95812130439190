

class ShareButton{
    btn;
    constructor(element){
        this.btn = element;

        this.btn.addEventListener('click', async (e) => {
            e.preventDefault();
         
            
            const shareData = {
                title: document.title,
                text: this.getDescription(),
                url: location.href
            };
            
           
            
             
            try {
                await navigator.share(shareData);
            } catch(err) {
                console.err(err);
            }
                

        });
    }

    getDescription(){
        var d = document.querySelector('meta[name="description"]');
        if(d){
            return d.getAttribute('content') || '';
        }
        return '';
    }
}


document.querySelectorAll('.share-button').forEach(function(button){
    new ShareButton(button);
});
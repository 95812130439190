
/**
 * Callbak odpalany gdy wykonał się load
 * ```
  winLoad(function() {
    console.log('Window is loaded');
  });
  ```
 * @param {function} callback 
 */
export function windownLoad(callback) {
    if (document.readyState === 'complete') {
      callback();
    } else {
      window.addEventListener("load", callback);
    }
}




/** 
Returns a function, that, as long as it continues to be invoked, will not
be triggered. The function will be called after it stops being called for
N milliseconds. If `immediate` is passed, trigger the function on the
leading edge, instead of the trailing.

*/
export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

/**
 * Promise based version of setTimeout
 * @param {Promise} duration 
 */
export function delay(duration) {

    return new Promise(function(resolve, reject){
        setTimeout(function(){
            resolve(duration);
        }, duration)
    });

};



  
 
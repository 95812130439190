function plnFormat(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ' zł';
}

/**
 * klient mi podał że to prosta prawidłowość: 2% od kwoty na/do 30 dni, 
 * potem z każdymi 30 dniami rośnie dodatków o kolejne 2%, czyli na 60 dni 4% itd.
 */
class Calculator{
    $container
    constructor({container = '.calculator' }={}){
        this.$container = $(container);
        this.$fvAmount = this.$container.find('#fvAmount');
        this.$fvDays = this.$container.find('#fvDays');
        if(this.$container.length){
            this.init();
        }
    }
    init(){
        this.$fvAmount.on('input', (e)=>{
            this.calculate();
            this.gradient(e.target);
        });
        this.$fvDays.on('input', (e)=>{
            this.calculate();
            this.gradient(e.target);
        });

        this.calculate()
        this.gradient(this.$fvAmount[0])
        this.gradient(this.$fvDays[0])
    }

    gradient(slider){
        const min = slider.min;
        const max = slider.max;
        const value = slider.value;

        slider.style.background = `linear-gradient(to right, #00FFD1 0%, #00FFD1 ${(value-min)/(max-min)*100}%, #EAEAEA ${(value-min)/(max-min)*100}%, #EAEAEA 100%)`
        // slider.style.setProperty('--range-track-bg', `linear-gradient(to right, #00FFD1 0%, #00FFD1 ${(value-min)/(max-min)*100}%, #EAEAEA ${(value-min)/(max-min)*100}%, #EAEAEA 100%)`)
        // this.style.background = `linear-gradient(to right, #00FFD1 0%, #00FFD1 ${(this.value-this.min)/(this.max-this.min)*100}%, #EAEAEA ${(this.value-this.min)/(this.max-this.min)*100}%, #EAEAEA 100%)`
    };


    calculate(){
        const amount = this.$fvAmount.val();
        const days = this.$fvDays.val();
        
        const percent = 0.02;
        const months = Math.ceil(days / 30);

        const actualPercent = percent * months;

        const payment = amount * actualPercent;

        const totalLeft = amount - payment;

        // log months
        console.log({months});

        $("[data-calc-days]").text(`${days} dni`);
        $("[data-calc-amount]").text(plnFormat(amount));
        $("[data-calc-total]").text(plnFormat(totalLeft));
        
    }

}


window.calc = new Calculator()
//   // import Swiper JS
//   import Swiper, { Navigation, Pagination }  from 'swiper';
//   // import Swiper styles
//   import 'swiper/css';
//   import 'swiper/css/navigation';
//   import 'swiper/css/pagination';

//   // configure Swiper to use modules
//   Swiper.use([Navigation, Pagination]);

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

// import 'rangeslider.js';
// import 'rangeslider.js/dist/rangeslider.css';

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { AutoResize } from './utils/AutoResize';

gsap.registerPlugin(Draggable);

function xx(i){
	var w = document.querySelector('.ico-scroll').clientWidth;
	var ww = w/4;
	console.log({w, ww});
	return ww/2 + i*ww;
}
var draggable;
function initDraggable(){
	function update(){
	
		var index = this.vars.liveSnap.indexOf(this.x);
		updateIcon(index);
		

		console.log({
			x: this.x,
			// liveSnap: this.liveSnap,
			t: this,
			index: index
		});
		
	}
	draggable = Draggable.create(".ico-scroll__handle", {
		type: "x", 
		x: xx(0),
		edgeResistance:0.65, 
		bounds:".ico-scroll", 
		inertia: false,
		liveSnap: [xx(0), xx(1), xx(2), xx(3)],
		onDrag: update,
		// liveSnap: true,
	});
	gsap.set('.ico-scroll__handle',{x: xx(0) });
	updateIcon(0);
	
}
if( document.querySelector('.ico-scroll') ){

	$(window).on('resize', function(){
		initDraggable();
	});
	initDraggable();
	
	var isAnimated = false;
	$('.ico-scroll').on('wheel', function(e){
		e.preventDefault();
		if(isAnimated){
			return;
		}
		var index = $('[data-ico-dot].active').index();
		var $quotes = $('[data-ico-dot]');
		if(e.originalEvent.deltaY < 0){
			index--;
		}else{
			index++;
		}
		if(index < 0){
			index = $quotes.length - 1;
		}else if(index > $quotes.length - 1){
			index = 0;
		}
		isAnimated = true;
		gsap.to(".ico-scroll__handle", {duration: 0.3, x: xx(index), onComplete:()=>{
			isAnimated = false;
		} });
		updateIcon(index);
		
	});

	
}

$('[data-ico-dot]').on('click', function(e){
	var index = $(this).attr('data-ico-dot');
	gsap.to(".ico-scroll__handle", {duration: 0.3, x: xx(index) });
	updateIcon(index);
});

new AutoResize({
	selector: '[data-zoom-content]',
	wrapperSelector: '[data-zoom-content-wrapper]',
});

new AutoResize({
	selector: '.quote__wrapper',
	wrapperSelector: '.quotes-repeater',
});



var index = 0;
var $quotes =  $('.quote__wrapper');
function QuotesRotating(){
	index = $('.quote__wrapper:visible').index();
	if(index === $quotes.length - 1){
		index = 0;	
	}else{
		index++;
	}
	$quotes.eq(index).show().siblings().hide();
	setTimeout(QuotesRotating, 5000);	
}
setTimeout(QuotesRotating, 5000);	



var id = 0;
function updateIcon(index){
	$('[data-index="'+index+'"]').addClass('active').siblings().removeClass('active');
	$('.ico-scroll__dot').eq(index).addClass('active').siblings().removeClass('active');
	
	$("[data-zoom-content]").hide().eq(index).show();
	
}



document.querySelectorAll('.tabs-swiper').forEach(el => {
	var swiper = new Swiper(el, {
		navigation: {
			nextEl: el.parentNode.querySelector('.swiper-button-next'),
			prevEl: el.parentNode.querySelector('.swiper-button-prev'),
		},
		slidesPerView: 1,
	});
});


export const partnersSwiper = new Swiper('#partnersSwiper', {
	
	// If we need pagination
	// pagination: {
	// 	el: '#partnersSwiper .swiper-pagination',
	// 	clickable: true
	// },
	centeredSlides: true,
	watchSlidesProgress: true,
	loop: true,

	// Navigation arrows
	// navigation: true,
	navigation: {
		nextEl: '#partnersSwiperNext',
		prevEl: '#partnersSwiperPrev',
	},
	// Default parameters
	slidesPerView: 2,
	spaceBetween: 20,
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 320px
		320: {
			slidesPerView: 1,
			loop: true,
			// spaceBetween: 0
		},
		// when window width is >= 480px
		480: {
			slidesPerView: 3,
			loop: true,
			// spaceBetween: 0
		},
		// when window width is >= 640px
		1000: {
			slidesPerView: 5,
			loop: true,
			// spaceBetween: 0
		}
	}
});


export const managementSwiper = new Swiper('#managementsSwiper', {
	
	// If we need pagination
	// pagination: {
	// 	el: '#managementSwiper .swiper-pagination',
	// 	clickable: true
	// },
	// centeredSlides: true,
	watchSlidesProgress: true,
	// loop: false,

	// Navigation arrows
	// navigation: true,
	scrollbar: {
		el: '.swiper-scrollbar',
		draggable: true,
	},
	navigation: {
		nextEl: '#managementsSwiperNext',
		prevEl: '#managementsSwiperPrev',
	},
	// Default parameters
	slidesPerView: 1,
	spaceBetween: 20,
	// Responsive breakpoints
	breakpoints: {
		// when window width is >= 320px
		720: {
			slidesPerView: 2,
			// loop: true,
			// spaceBetween: 0
		},
		// when window width is >= 480px
		1080: {
			slidesPerView: 3,
			// loop: true,
			// spaceBetween: 0
		},
	}
});

export const topSwiper = new Swiper('#topSwiper', {
	

	watchSlidesProgress: true,
	loop: false,
	autoplay: {
		delay: 10 * 1000,
		disableOnInteraction: false
	},
	threshold: 20,

	// Navigation arrows
	// navigation: true,
	// scrollbar: {
	// 	el: '.swiper-scrollbar',
	// 	draggable: true,
	// },
	navigation: {
		nextEl: '#topSwiperNext',
		prevEl: '#topSwiperPrev',
	},
	// Default parameters
	slidesPerView: 1,
	spaceBetween: 0,

});







export const zoomSwiper = new Swiper('#zoomSwiper', {
	

	navigation: false,
	watchSlidesProgress: true,
	observer: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	// Default parameters
	slidesPerView: 1,
	spaceBetween: 0,
	
	breakpoints: {
	
		1000: {
			slidesPerView: 4,
			// spaceBetween: 0
		}
	}
});

zoomSwiper.on('slideChange', swiper => {
	
	$("[data-zoom-content]").hide().eq(zoomSwiper.realIndex).show();
});


$('.zoom-slide').on('click', function(e){
	
	$(this).addClass('swiper-slide-active').siblings().removeClass('swiper-slide-active');
	$("[data-zoom-content]").hide().eq($(this).index() ).show();
});
